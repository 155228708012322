import React, { Component } from 'react';
import { OTSession, OTStreams } from 'opentok-react'
import { Row, Col } from 'reactstrap'
import Publisher from './Publisher';
import Subscriber from './Subscriber';
import injectSheet from 'react-jss';
import Spinner from '../../../assets/images/spinner.gif'
import Vittinho from '../../../assets/images/mascote_vittude_vFinal-10-min.png'


const styles = {
  load: {
    minHeight: '49vw',
    padding: '15px 0'
  },

  connection: {
    position: 'absolute',
    marginLeft: '30%',
    marginTop: '15%',
  },

  OTContainer: {
    width: '100%',
    height: '100%',
    margin: '10px',
  },


  '@media only screen and (max-width: 600px)': {
    load: {
      height: '158vw',
      padding: '15px 0'
    },

    OTContainer: {
      width: '95vw',
      height: 'auto',
      margin: '2.5vw',
      marginTop: '11.5vw'
    },

    connection: {
      marginTop: '20%',
      width: '50%',
      position: 'absolute',
      marginLeft: '21%'
    }
  },

  '@media only screen and (min-width: 601px) and (max-width: 990px)': {
    load: {
      height: '149vw',
      padding: '15px 0'
    },

    OTContainer: {
      width: '95vw',
      height: 'auto',
      margin: '2.5vw',
      marginTop: '11.5vw'
    },

    connection: {
      marginTop: '20%',
      width: '50%',
      position: 'absolute',
      marginLeft: '21%',
    }
  }
} 

class Session extends Component {
    constructor(props) {
        super(props);
        this.otSession = React.createRef();

        this.state = {
          error: null,
          connected: false,
          connections: []
        };

        this.sessionEvents = {
          connectionCreated: (event) => {
            this.setState({
              ...this.state,
              connections: [...this.state.connections, event.connection.connectionId] 
            })
          },
          connectionDestroyed: () => {
            console.log('connectionDestroyed')
          },
          sessionReconnected: () => {
            console.log('sessionReconnected')
          },
          sessionReconnecting: () => {
            console.log('sessionReconnecting')
          },
          streamDestroyed: () => {
            console.log('streamDestroyed')
          },
          streamPropertyChanged: () => {
            console.log('streamPropertyChanged')
          },
          streamCreated: () => {
            console.log('streamCreated')
          },
          sessionConnected: () => {
            this.setState({ connected: true });
        },
          sessionDisconnected: () => {
            this.setState({ connected: false });
          }
        };

        this.getSession = this.getSession.bind(this)
    }

    onError = (err) => {
      console.log(err)
      switch(err.name){
        case 'OT_NOT_CONNECTED':
        case 'OT_CONNECT_FAILED':
          this.setState({ error: `Não foi possível identificar uma conexão com a internet, por favor atualize a página` });
          break;
        case 'OT_AUTHENTICATION_ERROR':
          this.setState({ error: `Não foi possível realizar a conexão, por favor entre em contato com o nosso suporte` });
          break;
        default:
          this.setState({ error: `Não foi possível conectar: ${err.message}` });
      }
    }

    getSession(){
      return this.otSession.current.sessionHelper.session
    }

    render(){
        const { virtualConfig: { session_id, token }, apiKey, classes } = this.props;

        return(
            <section className={classes.load}>
              <Row className="justify-content-center align-items-center">
                <Col xs="12" style={{'margin': '10px'}}>
                  <OTSession
                    apiKey={apiKey}
                    sessionId={session_id}
                    token={token}
                    eventHandlers={this.sessionEvents}
                    onError={this.onError}
                    containerClasses={classes.OTContainer}
                    ref={this.otSession}
                  >
                    { this.state.error ? 
                      <Row>
                        <Col xs="12">
                          {this.state.error}
                        </Col>
                      </Row> 
                      : 
                      this.state.connections.length < 2 ?
                        <p className={classes.connection}>
                          <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Spinner} alt="" />
                            Aguardando a conexão
                        </p>
                      :
                      null
                    }
                    <Publisher 
                      connected={this.state.connected}
                      session={this.getSession}
                    />
                    <OTStreams>
                      <Subscriber />
                    </OTStreams>
                  </OTSession>
                </Col>
              </Row>
            </section>
        )
    }
}

export default injectSheet(styles)(Session)
