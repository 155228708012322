import React, { Component } from 'react'

export default class ConnectionStatus extends Component {
  render () {
    let status = this.props.connected ? 'Conectado' : 'Desconectado'
    return (
      <div className='col-lg-3 offset-lg-9 col-12 justify-content-center align-items-center'>
        <strong>Status:</strong> {status}
      </div>
    )
  }
}
