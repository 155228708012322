import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import NetworkTest, { ErrorNames } from 'opentok-network-test-js';
import Spinner from '../../../assets/images/spinner.gif'
import Check from '../../../assets/images/icon_tick.svg'
import Vittinho from '../../../assets/images/mascote_vittude_vFinal-10-min.png'


export default class QualityTest extends Component {
    constructor(props){
      super(props)
      this.notification = null;
      this.state = {
        errorMsgs: null
      }
    }

    componentDidMount(){
      this.notification = this.refs.qualityTest;
      const { OpenTokTest, saveTestResults, nextStep } = this.props;
      OpenTokTest.testQuality((stats) => {
          console.log('intermediate testQuality stats', stats);
        })
        .then((results) => {
            saveTestResults(results)
        }).catch((error) => {
            this.translateStatus(error)
          })
    }

    componentDidUpdate(prevProps){
      const { nextStep, testResults: { quality } } = this.props;
      if(!prevProps.testResults.quality && this.props.testResults.quality){
        nextStep()
      }
    }

    translateStatus(failedTests){
      let err = []
      failedTests.forEach((failedTest) => {
        switch (failedTest.error.name) {
          case ErrorNames.UNSUPPORTED_BROWSER:
           err.push('O navegador utilizado não é compatível com o consultório virtual')
            break;
          case ErrorNames.CONNECT_TO_SESSION_NETWORK_ERROR:
            err.push('Foram encontrados erros de conexão')
            break;
          case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
            err.push('Não foi possível acessar a camêra ou o microfone')
            break;
          case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
          case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
            err.push('Não foram encontrados a câmera ou o microfone')
            break;
          case "FailedToCreateLocalPublisher":
            err.push('Não foi possível enviar vídeo e/ou áudio. Verifique as permissões da câmera e microfone')
            break;
          default:
            err.push('Não foi possível completar o teste de qualidade da sessão')
          }
      })

      this.setState({
        ...this.state,
        errorMsgs: err.join(', ')
      })
    }

    render(){
      const { testResults: { quality } } = this.props;
      const { errorMsgs } = this.state;

      return(
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <img style={{'width':'100px', 'margin': '20px 0'}} src={Vittinho} alt="" />
            </div>
            {
              errorMsgs ? 
              <div className="col-12">
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Conectando à sessão de teste
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Adquirindo acesso à câmera
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Adquirindo acesso ao microfone
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                  Calculando velocidade da internet
                </p>
                  <p>{errorMsgs}</p>
              </div>
              :
              <div className="col-12">
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Conexão com os nossos servidores
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Adquirindo acesso à câmera
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                    Adquirindo acesso ao microfone
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Spinner} alt="" />
                    Calculando velocidade da internet
                </p>
              </div>
            }
            <NotificationSystem ref="qualityTest" />
          </div>
        </div>
        )

    }
}  
