import React, { Component } from 'react'
import Alert from './FooterAlert'
import Cards from './Cards'
import Intercom from './Intercom'
import PatNavy from '../../../assets/images/pat-marinho.png'
import HomeSprites from '../../../assets/images/home-sprites.png'
import injectSheet from 'react-jss'
import { Container, Row, Col } from 'reactstrap'

const styles = {
  infos: {
    backgroundImage: `url(${PatNavy})`,
    backgroundRepeat: 'repeat',
    color: '#FFF',
    paddingTop: '80px',
    paddingBottom: '30px',
    textAlign: 'left',
    fontSize: '15px',
  },
  pTitleFooter: {
    fontWeight: '500',
    padding: '10px 0'
  },
  ulFooter: {
    padding: '0'
  },
  liFooter: {
    fontWeight: '100',
    listStyleType: 'none',
    padding: '0'
  },
  aFooter: {
    color: '#FFF',
    textDecoration: 'underline',
    '&:hover': {
      color: '#fff'
    }
  },
  iuguImgVirtual: {
    composes: 'list-inline col-12',
    marginLeft: '-30px',
    width: '200px'
  },
  imgVisa: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '-208px 0',
    width: '2.7em',
    height: '2.7em'
  },
  imgMaster: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '-166px 0',
    width: '2.7em',
    height: '2.7em'
  },
  imgDiners: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '-82px 0',
    width: '2.7em',
    height: '2.7em'
  },
  imgAmex: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '0 -1px',
    width: '2.7em',
    height: '2.7em'
  },
  imgElo: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '-124px 0',
    width: '2.7em',
    height: '2.7em'
  },
  imgBoleto: {
    background: `url(${HomeSprites}) no-repeat`,
    backgroundPosition: '-41px 0',
    width: '2.7em',
    height: '2.7em'
  },
  cfp: {
    width: '120px',
    height: '13.125em',
    border: 'none',
    overflow: 'hidden'
  },
  cardsInfos: {
    composes: 'd-sm-flex d-md-flex d-lg-flex',
    float: 'right',
  },
  cvvFooter: {
    composes: 'col-12',
    padding: '.5% 0',
    backgroundColor: '#dfe0dc'
  },
  cvvP: {
    margin: '0',
    fontSize: '0.9rem',
    padding: '1% 15px',
    fontWeight: '100'
  },
  cvvA: {
    color: '#FF6A58'
  },
  cvvTitle: {
    fontWeight: '500',
    padding: '10px 0',
    textAlign: 'left',
    margin: '0'
  },
  cvvUl: {
    padding: '0'
  },
  cvvLi: {
    fontWeight: '100',
    listStyleType: 'none',
    padding: '0',
    textAlign: 'left'
  }
}

class Footer extends Component {
  constructor(props){
    super(props)
  }

  render () {
    const { classes } = this.props

    return (
      <footer className='margin-footer'>
        <section id='infos' className={classes.infos}>
          <Container>
            <Row>
              <Col sm="4" xs="12">
                <p className={classes.pTitleFooter}>A Vittude</p>
                <ul className={classes.ulFooter}>
                  <li className={classes.liFooter}>
                    <a href='https://www.vittude.com/faq' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Perguntas Frequentes
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a href='https://www.vittude.com/termos-paciente' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Cliente: Termos de Uso
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a href='https://www.vittude.com/termos-psicologos' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Psicólogo: Termos de Uso
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a href='https://www.vittude.com/privacidade' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Política de Privacidade
                    </a>
                  </li>
                </ul>
              </Col>
              <Col sm="4" xs="12">
                <p className={classes.pTitleFooter}>Links Recomendados</p>
                <ul className={classes.ulFooter}>
                  <li className={classes.liFooter}>
                    <a href='http://site.cfp.org.br/' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Conselho Federal de Psicologia
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a href='http://www.crpsp.org/site/' target='_blank' rel="noopener noreferrer" className={classes.aFooter}>
                      Conselho Regional de Psicologia
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a
                      href='http://site.cfp.org.br/wp-content/uploads/2012/07/Resoluxo_CFP_nx_011-12.pdf'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.aFooter}
                    >
                      Resolução CFP n 11/2012
                    </a>
                  </li>
                  <li className={classes.liFooter}>
                    <a
                      href='http://site.cfp.org.br/wp-content/uploads/2012/07/codigo_etica.pdf'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.aFooter}
                    >
                      Código de Ética do Psicólogo
                    </a>
                  </li>
                </ul>
              </Col>
              <Cards classes={classes}/>
            </Row>
          </Container>
        </section>
        <Alert classes={classes}/>
        <Intercom />
      </footer>
    )
  }
}

export default injectSheet(styles)(Footer)
