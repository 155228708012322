import React, { Component } from 'react'
import Routes from './Routes'
import injectSheet from 'react-jss'

const styles = {
    app: {
      textAlign: 'center',
      backgroundColor: '#fff',
      fontFamily: 'Fira Sans, sans-serif !important'
    }
}

class App extends Component {
  constructor(props){
    super(props)
  }

  render () {
    const { classes } = this.props

    return (
      <Routes 
        classes={classes.app}
        opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"
      />
    )
  }
}

export default injectSheet(styles)(App)
