import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import Vittinho from '../../../assets/images/mascote_vittude_vFinal-10-min.png'
import Check from '../../../assets/images/icon_tick.svg'


export default class TestResults extends Component {
    constructor(props){
        super(props)
        this.notification = null;
        this.startSession = this.startSession.bind(this)
    }

    componentDidMount(){
        this.notification = this.refs.testResults;
    }
    
    startSession(){
        const { startSession, testResults } = this.props;
        startSession(testResults)
    }

    render () {

        return (
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <img style={{'width':'100px', 'margin': '20px 0'}} src={Vittinho} alt="" />
                </div>
                <div className="col-12">
                  <p>
                    <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                      Conectando à sessão de teste
                  </p>
                  <p>
                    <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                      Adquirindo acesso à câmera
                  </p>
                  <p>
                    <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                      Adquirindo acesso ao microfone
                  </p>
                  <p>
                    <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={Check} alt="" />
                      Calculando velocidade da internet
                  </p>
                  <button
                    className={this.props.classes}
                    style={{ margin: '1rem 0 0 1rem', cursor: 'pointer' }}
                    onClick={this.startSession}
                  >
                    Iniciar a sessão
                  </button>
                </div>
              </div>
              <NotificationSystem ref="testResults" />
            </div>
        )
    }

}
