import React, { Fragment, Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import AtendimentoOnline from './pages/AtendimentoOnline'

const history = createBrowserHistory()

class Routes extends Component {
  constructor(props){
    super(props)

    console.log(props)
  }

  render () {
    return (
      <Router history={history}>
        <div className={this.props.classes}>
          <div className='container' />
          <Fragment>
            <Switch>
              <Route exact path='/:roomId' component={AtendimentoOnline} />
            </Switch>
          </Fragment>
        </div>
      </Router>
    )
  }
}

export default Routes
