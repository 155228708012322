import React from 'react';
import Cfp from './Cfp'
import Pagamentos from '../../../assets/images/pagamentos-por-iugu.png'
import { Container, Col, Row } from 'reactstrap'

export default props => (
    <Col md="12" lg="4" className={props.classes.cardsInfos}>
        <Col xs="7" md="7" lg="6" className="form-of-payment">
            <Row style={{margin: '0 !important'}}>
                <ul className={props.classes.iuguImgVirtual}>
                    <Col xs="12">
                        <li className="text-center">
                            <a 
                                target="_blank"  
                                href="https://iugu.com/pagamentos-por-iugu/"
                                rel="noopener noreferrer"
                            >
                            <img src={Pagamentos} alt="" style={{height: '44px', width: '190px', margin: '0 5px'}} />
                            </a>
                        </li>
                    </Col>
                </ul>
                <Col xs="12" md="6" lg="12">
                    <ul className="list-inline d-flex content-space-between">
                        <li className="col-md-4 text-center">
                            <div className={props.classes.imgVisa} />
                        </li>
                        <li className="col-md-4 text-center">
                            <div className={props.classes.imgMaster} />
                        </li>
                        <li className="col-md-4 text-center">
                            <div className={props.classes.imgDiners} />
                        </li>
                    </ul>
                </Col>
                <Col xs="12" md="6" lg="12">
                    <ul className="list-inline d-flex content-space-between">
                        <li className="col-md-4 text-center"><div className={props.classes.imgAmex} /></li>
                        <li className="col-md-4 text-center"><div className={props.classes.imgElo} /></li>
                        <li className="col-md-4 text-center"><div className={props.classes.imgBoleto} /></li>
                    </ul>
                </Col>
            </Row>
        </Col>
        <Col xs="5" sm="5" md="5" lg="5" id="cfp_badge">
            <Cfp classes={props.classes.cfp}/>
        </Col>
    </Col>
)
