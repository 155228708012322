import React, { Component } from 'react';
import PropTypes from 'prop-types';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args);
  } else {
    console.warn('Intercom not initialized yet');
  }
};

class Intercom extends Component {
  static propTypes = {
    appID: PropTypes.string.isRequired,
  };

  static displayName = 'Intercom';
  static defaultProps = { appID: 'u3f7fydw' };

  constructor(props) {
    super(props);

    const { appID } = props;

    if (!appID || !canUseDOM) {
      return;
    }
    if (!window.Intercom) {
      (function(w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    window.intercomSettings = {
      app_id: appID,
      custom_launcher_selector: '#open_intercom',
    };

    // if (window.Intercom) {
    //   window.Intercom('boot', window.intercomSettings);
    //   if (!window.Upscope) {
    //     (function(w, u, d) {
    //       if (typeof u !== 'function') {
    //         var i = function() {
    //           i.c(arguments);
    //         };
    //         i.q = [];
    //         i.c = function(args) {
    //           i.q.push(args);
    //         };
    //         w.Upscope = i;
    //         var l = function() {
    //           var s = d.createElement('script');
    //           s.type = 'text/javascript';
    //           s.async = true;
    //           s.src = 'https://code.upscope.io/uxUgZoM59eSrWEiNbw6VKCZP.js';
    //           var x = d.getElementsByTagName('script')[0];
    //           x.parentNode.insertBefore(s, x);
    //         };
    //         l();
    //       }
    //     })(window, window.Upscope, document);
    //     Upscope('init', {});
    //   }
    // }
  }

  componentWillReceiveProps(nextProps) {
    const { appID, ...otherProps } = nextProps;

    if (!canUseDOM) return;

    if (window.Intercom && otherProps.user !== undefined) {
      if (otherProps.user.role === 'professional') {
        window.intercomSettings = {
          name: otherProps.user.name,
          email: otherProps.user.email,
          user_hash: otherProps.user.intercom_hash,
          Telefone: otherProps.user.full_phone,
          'Eu sou': 'Psicólogo',
          Sexo: otherProps.user.gender,
          CPF: otherProps.user.cpf,
          CRP: otherProps.user.prof_register,
          'Data de nascimento': otherProps.user.birthday,
          Endereco: otherProps.user.address.street,
          Cidade: otherProps.user.address.city,
          Estado: otherProps.user.address.state,
          Newsletter: otherProps.user.newsletter,
          Slug: otherProps.user.slug,
          Status: otherProps.user.status_subscription,
          Plano: otherProps.user.plan,
        };
      } else {
        window.intercomSettings = {
          name: otherProps.user.name,
          email: otherProps.user.email,
          user_hash: otherProps.user.intercom_hash,
          Telefone: otherProps.user.full_phone,
          'Eu sou': 'Paciente',
          Sexo: otherProps.user.gender,
          CPF: otherProps.user.cpf,
          'Data de nascimento': otherProps.user.birthday,
          Endereco: otherProps.user.address
            ? otherProps.user.address.street
            : null,
          Cidade: otherProps.user.address ? otherProps.user.address.city : null,
          Estado: otherProps.user.address
            ? otherProps.user.address.state
            : null,
          Newsletter: otherProps.user.newsletter,
          Status: otherProps.user.status
        };
      }
      window.Intercom('update', window.intercomSettings);
    } else {
      window.Intercom('shutdown');
      window.Intercom('boot', { app_id: appID });
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return false;
  }
}

export default Intercom;
