import React, { Component } from 'react';
import { OTPublisher } from 'opentok-react'
import injectSheet from 'react-jss';
import CheckBox from './Checkbox';
import ConnectionStatus from './ConnectionStatus';
import OTChat from './OTChat';

const styles = {

  publisher: {},

  OTPublisherContainer: {
    position: 'absolute',
    zIndex: '1',
    marginTop: '30%',
    height: '10vw',
    width: '17vw',
    marginLeft: '2%',
  },

  '@media only screen and (max-width: 600px)': {
    OTPublisherContainer: {
      position: 'absolute',
      width: '25%',
      height: '20vw',
      margin: 'auto',
      marginTop: '-26vw',
      zIndex: '2',
      padding: '1%',
      marginLeft: '4%'
    },
    publisher: {
      float: 'right',
      width: '100%',
      marginTop: '69vw'
    }
  },

  '@media only screen and (min-width: 601px) and (max-width: 990px)': {
    OTPublisherContainer: {
      position: 'absolute',
      width: '25%',
      height: '20vw',
      margin: 'auto',
      marginTop: '-24vw',
      zIndex: '2',
      padding: '1%',
      marginLeft: '4%'
    },
    publisher: {
      float: 'right',
      width: '100%',
      marginTop: '67vw'
    }
  }
}



class Publisher extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      error: null,
      audio: true,
      video: true,
      session: null
    };

    this.publisherEvents = {
      streamCreated: event => {
        console.log('Publisher stream created!');
      },
      streamDestroyed: event => {
        console.log('Publisher stream destroyed!');
      },
      accessAllowed: () => {
        console.log('accessAllowed')
      },
      accessDenied: () => {
        console.log('accessDenied')
      },
      accessDialogClosed: () => {
        console.log('accessDialogClosed')
      },
      accessDialogOpened: () => {
        console.log('accessDialogOpened')
      },
      streamPropertyChanged: () => {
        console.log('streamPropertyChanged')
      },
      videoDimensionsChanged: () => {
        console.log('videoDimensionsChanged')
      },
      videoDimensionsChanged: () => {
        console.log('videoDimensionsChanged')
      },
      videoElementCreated: () => {
        console.log('videoElementCreated')
      },
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      }
    };

    this.publisherProperties = {
      preferredFrameRate: 15,
      subscribeToAudio: this.state.audio,
      subscribeToVideo: this.state.video,
      audioBitrate: 8000,
      audioFallbackEnabled: true,
      insertDefaultUI: true,
      insertMode: 'append',
      showControls: true,
      width: '100%',
      height: '10vw',
      publishAudio: this.state.audio,
      publishVideo: this.state.video,
      };
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.session === null){
      console.log('here')
      this.setState({
        ...this.state,
        session: this.props.session()
      })
    } else if(prevState.session !== null && this.state.session !== prevState.session){
      console.log('here2')
      this.setState({
        ...this.state,
        session: this.props.session()
      })
    }
  }

  onPublish = () => {
    console.log('Publish Success');
  };

  setAudio = (audio) => {
    this.setState({ audio });
  }

  setVideo = (video) => {
    this.setState({ video });
  }

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  }

  onInit = () => {
    console.log('onInit')
  }

  render() {
    const { session } = this.state
    const { classes } = this.props

    return (
      <div className={classes.publisher}>
        {
          this.state.error 
          ? 
          <div>{this.state.error}</div> 
          : 
          null
        }
        <ConnectionStatus 
          connected={this.props.connected} 
        />
        {
          session &&
          <OTChat 
            session={session} 
          />
        }
        <div className={classes.OTPublisherContainer}>
          <OTPublisher
            properties={this.publisherProperties}
            onError={this.onError}
            eventHandlers={this.publisherEvents}
            onPublish={this.onPublish}
            onInit={this.onInit}
          />
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Publisher)
