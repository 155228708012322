import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import NetworkTest, { ErrorNames } from 'opentok-network-test-js';
import Vittinho from '../../../assets/images/mascote_vittude_vFinal-10-min.png'
import ErrorTick from '../../../assets/images/icon_error.svg'
import Spinner from '../../../assets/images/spinner.gif'


export default class ConnectivityTest extends Component {
    constructor(props){
      super(props)
      this.state = {
        errorMsgs: null
      }
      this.translateStatus = this.translateStatus.bind(this)
      this.notification = null;
    }

    componentDidMount(){
      window.scrollTo(0, 0)
      this.notification = this.refs.connectivityTest;
      const { OpenTokTest, nextStep, saveTestResults } = this.props;
      OpenTokTest.testConnectivity()
        .then((results) => {
          saveTestResults(results)
          if(!results.success){
            this.translateStatus(results.failedTests)
          }
        })
        .catch(error => {
           this.notification.addNotification({
              title: 'Erro',
              message: 'Não foi possível realizar o teste de conexão da sessão',
              level: 'error',
              position: 'tc'
          });
        });
    }

    translateStatus(failedTests){
      let err = []
      failedTests.forEach((failedTest) => {
        console.log(failedTest)
        switch (failedTest.error.name) {
          case ErrorNames.CONNECT_TO_SESSION_ID_ERROR:
          case ErrorNames.CONNECT_TO_SESSION_TOKEN_ERROR:
            err.push('Os dados para a sessão estão incorretos')
            break;
          case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
            err.push('Não foi possível acessar a camêra ou o microfone')
            break;
          case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
          case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
            err.push('Não foram encontrados a câmera ou o microfone')
            break;
          case "FailedToCreateLocalPublisher":
            err.push('Não foi possível enviar vídeo e/ou áudio. Verifique as permissões da câmera e microfone')
            break;
          default:
            err.push('Não foi possível completar o teste de conectividade')
          }
      })

      this.setState({
        ...this.state,
        errorMsgs: err.join(', ')
      })
    }

    componentDidUpdate(prevProps){
      const { nextStep, testResults: { connectivity } } = this.props;
      if(!prevProps.testResults.connectivity && this.props.testResults.connectivity.success){
        nextStep()
      }
    }

    render(){
      const { testResults: { connectivity } } = this.props;
      const { errorMsgs } = this.state;
      return(
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <img style={{'width':'100px', 'margin': '20px 0'}} src={Vittinho} alt="" />
            </div>
            {
              errorMsgs ? 
              <div className="col-12">
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px' }} src={ErrorTick} alt="" />
                  Identificamos os problemas abaixo no teste de conectividade:
                </p>
                  <p>{errorMsgs}</p>
              </div>
              :
              <div className="col-12">
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px'}} src={Spinner} alt="" />
                  Conectando à sessão de teste
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px'}} src={Spinner} alt="" />
                  Adquirindo acesso à câmera
                </p>
                <p>
                  <img style={{ width: '1rem', height: '1rem', marginRight: '10px'}} src={Spinner} alt="" />
                  Adquirindo acesso ao microfone
                </p>
              </div>
            }
          </div>
          <NotificationSystem ref="connectivityTest" />
        </div>
        )

    }
}
