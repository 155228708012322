import React, { Component } from 'react'
import NetworkTest, { ErrorNames } from 'opentok-network-test-js'
import NotificationSystem from 'react-notification-system'
import TestResults from './TestResults'
import ConnectivityTest from './ConnectivityTest'
import QualityTest from './QualityTest'
import injectSheet from 'react-jss'
import Vittinho from '../../../assets/images/mascote_vittude_vFinal-10-min.png'

const styles = {
  lobbyLoad: {
    minHeight: '30vw',
    padding: '15px 0',
  },

  buttonVerm: {
    backgroundColor: '#FF6A58',
    borderColor: 'inherit',
    borderRadius: '4px',
    borderStyle: 'none',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: '500',
    padding: '20px 30px',
    opacity: '0.9',
    '&:hover': {
      opacity: '1'
    },
    '&:disabled': {
      backgroundColor: '#CCC',
      cursor: 'not-allowed'
    }
  },
  '@media only screen and (max-width: 600px)': {
    lobbyLoad: {
      marginBottom: '10vw'
    }
  }
}

class Lobby extends Component {
  constructor (props) {
    super(props)
    this.state = {
      testConfig: {
        apiKey: props.apiKey,
        sessionId: null,
        token: null
      },
      options: { timeout: 15000 },
      testResults: {
        connectivity: null,
        quality: null
      },
      progress: 0,
      OpenTokTest: null
    }
    this.startTest = this.startTest.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.saveTestResults = this.saveTestResults.bind(this)
    this.notification = null
  }

  componentDidMount () {
    this.notification = this.refs.lobby

  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.error === !this.props.error && this.props.error === false) {
      this.props
        .testCredentials(this.props.roomId)
        .then(res => {
          console.log(res)
          this.setState({
            ...this.state,
            testConfig: {
              ...this.state.testConfig,
              sessionId: res.session_id,
              token: res.token
            }
          })
        })
        .catch(err => {
          this.notification.addNotification({
            title: 'Erro',
            message:
              'Não foi possível criar as credenciais de teste. Por favor, atualize a página',
            level: 'error',
            position: 'tc'
          })
        })
    }
    if (
      !prevState.testResults.connectivity &&
      this.state.testResults.connectivity
    ) {
      if (this.state.testResults.connectivity.success) { console.log('Tem vídeo e microfone') }
    }
    if(prevProps.jumpTest === false && this.props.jumpTest === true){
      this.setState({
        ...this.state,
        progress: 3
      })
    }  
  }

  startTest () {
    const { testConfig, options } = this.state
    try {
      // eslint-disable-next-line 
      const otNetworkTest = new NetworkTest(OT, testConfig, options)

      this.setState({
        ...this.state,
        OpenTokTest: otNetworkTest,
        progress: this.state.progress + 1
      })
    } catch (error) {
      switch (error.name) {
        case ErrorNames.MISSING_OPENTOK_INSTANCE:
          this.notification.addNotification({
            title: 'Erro',
            message:
              'Não foi possível iniciar a conexão com o nosso servidor, por favor atualize a página',
            level: 'error',
            position: 'tc'
          })
          break
        case ErrorNames.INCOMPLETE_SESSON_CREDENTIALS:
        case ErrorNames.MISSING_SESSON_CREDENTIALS:
        case ErrorNames.INVALID_SESSON_CREDENTIALS:
          this.notification.addNotification({
            title: 'Erro',
            message:
              'Os dados da sessão não existem, entre em contato com o nosso suporte',
            level: 'error',
            position: 'tc'
          })
          break
        default:
          console.log(error)
          this.notification.addNotification({
            title: 'Erro',
            message: 'Ocorreu um erro, entre em contato com o nosso suporte',
            level: 'error',
            position: 'tc'
          })
      }
    }
  }

  nextStep () {
    this.setState({
      ...this.state,
      progress: this.state.progress + 1
    })
  }

  saveTestResults (results) {
    // TODO SALVAR OS RESULTADOS DOS TESTES
    const { progress } = this.state
    if (progress === 1) {
      this.setState({
        ...this.state,
        testResults: {
          ...this.state.testResults,
          connectivity: results
        }
      })
    } else {
      this.setState({
        ...this.state,
        testResults: {
          ...this.state.testResults,
          quality: results
        }
      })
    }
  }

  render () {
    const { error, startSession, classes } = this.props
    const { progress, testResults, OpenTokTest } = this.state
    return (
      <section className={classes.lobbyLoad}>
        {progress === 0 && (
          <div className='container'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12'>
                <img
                  style={{ width: '100px', margin: '20px 0' }}
                  src={Vittinho}
                  alt=''
                />
              </div>
              <div className='col-12'>
                <p>Bem vindo ao consultório online da Vittude :)</p>
                <p>
                  Para que você possa aproveitar sua sessão ao máximo, é
                  importante seguir algumas recomendações:
                </p>
                <p>
                  Recomendamos o uso de Google Chrome, Mozilla Firefox ou
                  Safari.
                </p>
                <p>Tenha um bom sinal de Wi-Fi.</p>
                <p>
                  Lembrando que o não comparecimento é considerado como falta e
                  o valor não será restituido.
                </p>
                <p>
                  Para começar, basta clicar no botão Iniciar Testes abaixo:
                </p>
                <button
                  className={classes.buttonVerm}
                  onClick={this.startTest}
                  disabled={error}
                >
                  Iniciar Testes
                </button>
              </div>
            </div>
          </div>
        )}
        {progress === 1 && (
          <ConnectivityTest
            nextStep={this.nextStep}
            OpenTokTest={OpenTokTest}
            saveTestResults={this.saveTestResults}
            testResults={testResults}
          />
        )}
        {progress === 2 && (
          <QualityTest
            nextStep={this.nextStep}
            OpenTokTest={OpenTokTest}
            saveTestResults={this.saveTestResults}
            testResults={testResults}
          />
        )}
        {progress === 3 && (
          <TestResults testResults={testResults} startSession={startSession} classes={classes.buttonVerm}/>
        )}
        <NotificationSystem ref='lobby' />
      </section>
    )
  }
}

export default injectSheet(styles)(Lobby)
