import React, { Component } from 'react'
import PropTypes from 'prop-types'
import once from 'lodash/once'
import uuid from 'uuid'
import injectSheet from 'react-jss';


const styles = {
  OTChat: {
    height: '41vw',
    backgroundColor: '#d3d3d3',
    padding: '0',
    width: '24%',
    marginLeft: '74%',
    position: 'absolute',
  },
  Scroll: {
    backgroundColor: '#EEE',
    width: '100%',
    minHeight: '38vw',
    maxHeight: '38vw',
    overflowY: 'scroll',
    overflowX:  'hidden',
    padding: '1rem'
  },
  p: {
    borderRadius: '5px',
    padding: '0 5px 0 5px',
    textAlign: 'justify',
  },
  span: {
    wordBreak: 'break-word',
    color: '#000'
  },
  small: {
    wordBreak: 'break-word',
    color: '#000'
  },
  myMessages: {
    backgroundColor: '#fff'
  },
  otherMessages: {
    backgroundColor: '##b2b0b2'
  },

  chatForm: {
    padding: '0.5em',
    width: '100%',
    backgroundColor: '#EEE', 
  },
  inputGroup: {
    composes: 'input-group',
    width: '100%',
    cursor: 'pointer',
    fontSize: '18px',
  },
  chatInput: {
    fontSize: '18px ! important',
    border: '0px'
  },

  input: {
    '&::-webkit-input-placeholder': {
      fontSize: '18px !important',
      lineHeight: '3'
    }
  },
  '@media only screen and (max-width: 600px)': {
    OTChat: {
      height: '38vw',
      backgroundColor: '#d3d3d3',
      padding: '0',
      width: '91vw',
      marginLeft: '4%',
      position: 'absolute',
    },
  },

  '@media only screen and (min-width: 601px) and (max-width: 990px)': {
    OTChat: {
      height: '100%',
      backgroundColor: '#d3d3d3',
      padding: '0',
      width: '91vw',
      marginLeft: '4%',
      position: 'absolute',
    },
    Scroll: {
      backgroundcolor: '#EEE',
      width: '100%',
      minHeight: '63vw',
      maxHeight: '63vw',
      overflowY: 'scroll',
      overflowX:  'hidden',
      padding: '1rem'
    }
  }
}

class OTChat extends Component {
  constructor (props) {
    super(props)
    this.state = {
      messages: [],
      newMessage: '',
      canUploadFiles: false
    }

    // this.handleClickInput = this.handleClickInput.bind(this)
    // this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    if (window.File && window.FileReader && window.Blob) {
      this.setState({
        ...this.state,
        canUploadFiles: true
      })
    }
    this.initChat()
  }

  componentDidUpdate (prevProps) {
    if (this.props.session !== prevProps.session) {
      this.closeChat()
      this.initChat()
    }
  }

  componentWillUnmount () {
    if (this.props.session) {
      this.closeChat()
    }
  }

  initChat = () => {
    this.props.session.on('signal:msg', this.receiveMessage)
  }

  closeChat = () => {
    this.props.session.off('signal:msg', this.receiveMessage)
  }

  receiveMessage = event => {
    this.setState({
      messages: [
        ...this.state.messages,
        {
          data: event.data.message,
          dateTime: event.data.dateTime,
          me:
            event.from.connectionId ===
            this.props.session.connection.connectionId
        }
      ]
    })
  }

  sendMessage = event => {
    event.preventDefault()
    if (this.state.newMessage !== '') {
      this.props.session.signal(
        {
          type: 'msg',
          data: {
            message: this.state.newMessage,
            dateTime: new Date().getTime()
          }
        },
        error => {
          if (error) {
            console.log('Error sending signal:', error.name, error.message)
          } else {
            this.setState({ newMessage: '' })
          }
        }
      )
    }
  }

  sendMessageOnEnter = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.sendMessage(event)
    }
  }

  handleInput = event => {
    this.setState({
      newMessage: event.target.value
    })
  }

  // handleClickInput(e) {
  //   this.refs.fileUploader.click()
  // }

  // handleFileUpload(e) {
  //   // console.log(e.target.files)
  //   const file = e.target.files[0]
  //   if (file && file.type.match('image.*')) {
  //       if(parseInt(file.size) < 1000000){
  //         const reader = new FileReader()
  //         reader.readAsDataURL(file);
  //         console.log(reader)
  //       } else {
  //         alert('File too big')
  //       }
  //   } else {
  //     alert('Not Image')
  //   }
  // }

  createConnection () {
    const localConnection = new RTCPeerConnection()
    console.log('Created local peer connection object localConnection')

    const sendChannel = localConnection.createDataChannel('sendDataChannel')
    sendChannel.binaryType = 'arraybuffer'
    console.log('Created send data channel')
  }

  formatTime = dateTime => {
    const date = new Date(dateTime)
    return `${date.getHours()}:${date.getMinutes()}`
  }

  renderMessages = () => {
    const { classes } = this.props

    return this.state.messages.map(msg => {
      return (
        <p
          key={msg.dateTime}
          className={msg.me ? `${classes.myMessages} ${classes.p} clearfix` : `clearfix ${classes.p} ${classes.otherMessages}`}
        >
          <span className={classes.span}>{msg.data}</span>
          <br />
          <small className={classes.small}>{this.formatTime(msg.dateTime)}</small>
        </p>
      )
    })
  }

  render () {
    const { canUploadFiles } = this.state
    const { classes } = this.props

    return (
      <div className={`col-12 ${classes.OTChat}`}>
        <div className={classes.Scroll}>{this.renderMessages()}</div>
        <form
          className={classes.chatForm}
          onSubmit={e => e.preventDefault()}
        >
          <div className={classes.inputGroup}>
            <input
              type='text'
              className={`form-control ${classes.chatInput}`}
              placeholder='Digite uma mensagem...'
              onChange={this.handleInput}
              onKeyDown={this.sendMessageOnEnter}
              value={this.state.newMessage}
            />
            {canUploadFiles && (
              <div
                className='input-group-addon'
                style={{ background: '#fff', border: '0px' }}
              >
                <img
                  src='../../assets/images/paperclip-archive.svg'
                  onClick={this.handleClickInput}
                  style={{
                    width: '15px',
                    background: '#fff'
                  }}
                  alt=""
                />
                <input
                  className={classes.input}
                  type='file'
                  name=''
                  id='file'
                  ref='fileUploader'
                  style={{ display: 'none' }}
                  onChange={this.handleFileUpload}
                />
              </div>
            )}
            <div className='input-group-addon' style={{ border: '0px' }}>
              <span onClick={this.sendMessage} className='input-group-text'>
                Enviar
              </span>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default injectSheet(styles)(OTChat)

OTChat.propTypes = {
  session: PropTypes.shape({
    connection: PropTypes.shape({
      connectionId: PropTypes.string
    }),
    once: PropTypes.func,
    off: PropTypes.func,
    publish: PropTypes.func,
    unpublish: PropTypes.func
  }),
  containerClasses: PropTypes.string
}

OTChat.defaultProps = {
  session: null,
  containerClasses: null
}
