import React from 'react';
import Loading from '../../assets/images/mascote_vittude_vFinal-17.png'

export default (props) => {
      return(
      <section 
        className="load" 
        style={{ 
          'minHeight': '75vh', 
          'display': 'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'flexDirection': 'column',
          'backgroundColor': '#fff'}}>
        <img 
          src={Loading} 
          alt="loading"
          style={{
            'width': '20%',
            'height': 'auto'
          }} />
        <h2>Carregando o consultório virtual ...</h2>
      </section>
      )
  }
