import React, { Component } from 'react';
import Session from './Session';
import { navHeight } from '../../utils/helpers'
import Lobby from './Lobby';
import Title from '../../components/shared/Title';
import NotificationSystem from 'react-notification-system';
import { getRoomData, getTestCredentials } from '../../services/virtualConfigs';
import PatVerm from '../../assets/images/pat-verm.png'
import injectSheet from 'react-jss'

const styles = {
    page: {
      marginTop: '97px'
    },
    pageTitle: {
      backgroundImage: `url(${PatVerm})`,
    },
    pTitle: {
      color: '#FFF',
      padding: '1% 1%',
      margin: '1% 0 0 0',
      textAlign: 'left',
      fontSize: '3vw',
      fontWeight: '100'
    },
    '@media only screen and (max-width: 600px)': {
      page: {
        marginTop: '85px'
      },
      pTitle:{
        padding: '2% 1%',
        fontSize: '6vw'
      }
    }
}


class ConsultorioVirtual extends Component{
    constructor(props){
        super(props)
        this.state = {
            canStartSession: false,
            virtualConfig: null,
            chatConfigs: null,
            error: true
        };

        this.notification = null;
        this.startSession = this.startSession.bind(this)
    }

    componentDidMount(){
      const { roomId } = this.props
      this.notification = this.refs.consultorioVirtual;
      this.getSessionCredentials(roomId)
    }

    componentDidUpdate(prevProps, prevState){
      const { jumpTest } = this.props 
      if(prevProps.jumpTest !== jumpTest){
        //TO DO PARA JUMPTEST
      }
    }

    startSession(results){
        this.setState({
            ...this.state,
            canStartSession: true,
            chatConfigs: results
        })
    }

    getSessionCredentials(roomId){
        getRoomData(roomId)
          .then(res => {
            this.setState({
              ...this.state,
              virtualConfig: res,
              error: false
            })
          })
          .catch(error => {
            this.notification.addNotification({
                title: 'Erro',
                message: 'Não foi possível localizar a sua consulta',
                level: 'error',
                position: 'tc'
            });
          })
    }

    render(){
        const { canStartSession, virtualConfig, error } = this.state;
        const { apiKey, roomId, classes, jumpTest } = this.props;

        return(
        <div className={classes.page}>
            {
                !canStartSession ? 
                    <div>
                        <Title title="Atendimento Online" classes={classes} />
                        <Lobby 
                          apiKey={apiKey}
                          testCredentials={getTestCredentials}
                          roomId={roomId}
                          error={error}
                          startSession={this.startSession}
                          jumpTest={jumpTest}
                        />
                    </div>
                    :
                    <Session 
                        apiKey={apiKey}
                        virtualConfig={virtualConfig}
                    />
            }
             <NotificationSystem ref="consultorioVirtual" />
        </div>
    )}
}
export default injectSheet(styles)(ConsultorioVirtual)
