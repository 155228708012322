import React, { Component } from 'react'
import NotificationSystem from 'react-notification-system'
import { Navbar, NavbarBrand } from 'reactstrap'
import Logo from '../../assets/images/logo-vittude.svg'
import injectSheet from 'react-jss'

const styles = {
  navBar: {
    composes: 'navbar navbar-expand-lg navbar-light fixed-top',
    visibility: 'visible',
    zIndex: '999',
    background: '#FFF',
    color: '#000',
    fontWeight: '100',
    padding: '8px 0',
    position: 'fixed',
    right: '0',
    left: '0',
    top: '0',
    boxShadow: '2px 2px 29px 0 rgba(0,0,0,.43)' 
  },
  container: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'left',
    padding: '10px 15px'
  },

  '@media (max-width: 1200px)': {
    container: {
      maxWitdh: '1140px',
      padding: '0'
    },
  }
}

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      showBackDrop: false,
      location: 'VIRTUAL',
    }
  }

  toggle = () => {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    })
  }

  toggleBackDrop = () => {
    this.setState({
      ...this.state,
      showBackDrop: !this.state.showBackDrop
    })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <header ref='headerTag'>
          <Navbar className={classes.navBar} id='nav-bar'>
            <div className={classes.container}>
              <div className='d-flex' style={{ flex: 1 }}>
                <div className='container'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <NavbarBrand className='navbar-brand'>
                      <img
                        src={Logo}
                        alt='Vittude'
                        title='Logo da Vittude'
                      />
                    </NavbarBrand>
                  </div>
                </div>
              </div>
            </div>
          </Navbar>
          <NotificationSystem ref='header' />
        </header>
      </div>
    )
  }
}

export default injectSheet(styles)(Header)
