import React, { Component } from 'react';
import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer/Footer';
import Loading from '../components/shared/Loading';
import ConsultorioVirtual from '../components/ConsultorioVirtual'
import { TOKBOXAPI_KEY } from '../config';
import { preloadScript } from 'opentok-react';
import queryString from 'query-string'

class AtendimentoOnline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 'VIRTUAL',
      jumpTest: false
    }

    
  }

  componentDidMount(){
    const parsed = queryString.parse(this.props.location.search)
    
    if(parsed.jumpTest === "true") {
      this.setState({
        ...this.state,
        jumpTest: true
      })
    }
  }

  render() {
    const { location, jumpTest } = this.state;
    const { roomId } = this.props.match.params;

    return (
      <div>
        <Header location={location} />
          <ConsultorioVirtual
            jumpTest={jumpTest}
            roomId={roomId}
            apiKey={TOKBOXAPI_KEY}
            loadingDelegate={<Loading />}
            opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"
          />
        <Footer location={location} />
      </div>
    );
  }
}
export default preloadScript(AtendimentoOnline);
