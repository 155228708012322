import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { OTSubscriber } from 'opentok-react';


const styles = {
  OTSubscriberContainer: {
    marginLeft: '1%',
    position: 'absolute',
  },
  subscriber: {
    height: '41.4vw',
    width: '73vw'
  },

  '@media only screen and (max-width: 600px)': {
    OTSubscriberContainer: {
      position: 'relative',
      height: '70vw',
      width: '91vw',
      marginLeft: '3%',
      marginTop: '6vw'
    },
    subscriber: {
      position: 'absolute',
      float: 'left',
      width: '100vw',
      zIndex: '1',
      marginTop: '-7%',
      padding: '1%'
    }
  },

  '@media only screen and (min-width: 601px) and (max-width: 990px)': {
    OTSubscriberContainer: {
      position: 'relative',
      height: '70vw',
      width: '91vw',
      marginLeft: '3%',
      marginTop: '3%'
    },
    subscriber: {
      position: 'absolute',
      float: 'left',
      width: '100vw',
      zIndex: '1',
      marginTop: '-7%',
      padding: '1%'
    }
  }
}


class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true
    };

    const screen = this.screenSize()

    this.subscriberEventHandlers = {
      videoDisabled: event => {
        console.log('Subscriber video disabled!');
      },
      videoEnabled: event => {
        console.log('Subscriber video enabled!');
      }
    };
    this.subscriberProperties = {
      preferredFrameRate: 15,
      showControls: true,
      insertMode: 'append',
      subscribeToAudio: this.state.audio,
      subscribeToVideo: this.state.video,
      height: screen === true ? '41.4vw' : '70vw',
      width: screen === true ? '73vw' : '91vw'
    };
  }

  screenSize = () => {
    const mq = window.matchMedia( "(min-width: 991px)" )

    if(mq.matches){
      return true
    }
    return false
  }

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.subscriber}>
        {this.state.error ? <div>{this.state.error}</div> : null}
        <div className={classes.OTSubscriberContainer}>
          <OTSubscriber
            stream={this.props.stream}
            session={this.props.session}
            properties={this.subscriberProperties}
            onError={this.onError}
          />
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Subscriber)
