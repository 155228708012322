import api from '../providers/fetchClient'

export const getRoomData = (id) => {
    const params = {
        consultation_id: id,
        media: 'routed',
    };
    return new Promise((resolve, reject) => {
        api.post(`/virtual_clinic/${id}/get_opentok_credentials/`, params)
            .then(res => {
                resolve(res.data.content)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getTestCredentials = (id) => {
    return new Promise((resolve, reject) => {
        api.get(`/virtual_clinic/${id}/get_opentok_test_credentials`)
            .then(res => {
                resolve(res.data.content)
            })
            .catch(err => {
                reject(err)
            })
    })
}
