import axios from 'axios'
import { __API__ } from '../config'

const fetchClient = () => {
  const defaultOptions = {
    baseURL: __API__
  }

  let instance = axios.create(defaultOptions)

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )

  return instance
}

export default fetchClient()
