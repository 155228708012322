import React from 'react';
import Alerta from '../../../assets/images/alerta.svg'

export default props => (
    <section id="cvv-footer" className={props.classes.cvvFooter}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <p className={props.classes.cvvTitle}>Responsável Técnico</p>
                    <ul className={props.classes.cvvUl}>
                        <li className={props.classes.cvvLi}>Felipe Fernandes de Lima</li>
                        <li className={props.classes.cvvLi}>CRP (06/127700)</li>
                    </ul>
                </div>
                <div className="hidden-xs col-sm-1 text-center">
                    <img src={Alerta} alt="Ícone de Alerta" title="Ícone de Alerta" />
                </div>
                <div className="col-md-8 col-12">
                    <p className={props.classes.cvvP}>Atenção: Este site não oferece tratamento ou aconselhamento imediato para pessoas em crise suicida. <br className="hidden-md-down" /> Em caso de crise, ligue para <span><a className={props.classes.cvvA} href="tel:+55141">188 (CVV)</a></span> ou acesse o site <span><a className={props.classes.cvvA} href="http://www.cvv.org.br/" target="_blank" rel="noopener noreferrer">www.cvv.org.br</a></span>. Em caso de emergência, procure atendimento em um hospital mais próximo.</p>
                </div>
            </div>
        </div>
    </section>
)
