import React from 'react';

export default (props) => (
    <section className={props.classes.pageTitle}>
        <div className="container">
            <div className="row no-gutters">
                <div className="col-12"> 
                    <h1 className={props.classes.pTitle}>{props.title}</h1>
                </div>
            </div>
        </div>
    </section>
)
